




import { computed, defineComponent } from '@vue/composition-api'
import { AppModule, DeviceType } from '@/store/modules/app'

export default defineComponent({
  name: 'SmartSportsDetails',
  components: {
    SmartSportsDetailsPhone: () => import('./phone.vue'),
    SmartSportsDetailsDesk: () => import('./desk.vue')
  },
  setup() {
    const zyType = computed(() =>
      AppModule.device === DeviceType.Desktop
        ? 'SmartSportsDetailsDesk'
        : 'SmartSportsDetailsPhone'
    )
    return { zyType }
  }
})
